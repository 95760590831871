

import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import Header from '@/modules/common/components/header.vue';
import Sidebar from '@/modules/common/components/sidebar.vue';
import ChatButton from '@/modules/zendesk-chat/components/chat-button.vue';
import CarsSidebar from '@/modules/cars/components/cars-sidebar.vue';
import CarsHeader from '@/modules/cars/components/cars-header.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import WalkmeWidget from '@/modules/walkme/components/walkme-widget.vue';

@Component({
    components: {
        Header, Sidebar, CarsSidebar, CarsHeader, WalkmeWidget, ChatButton,
    },
})
export default class PageLayout extends Vue {
    @inject(UserServiceS) private userService!: UserService;
}
