
import {
    Vue,
    Component,
    Prop,
    Watch,
} from 'vue-property-decorator';
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import CustomDropdownOption from './custom-dropdown-option.vue';

export interface IDropdownItem {
    text: string,
    link?: string,
    action?: Function,
    disable?: boolean,
    isVisible?: boolean,
}

export type DropdownMenuAlignment = 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end';

@Component({
    components: {
        'el-dropdown': Dropdown,
        'el-dropdown-menu': DropdownMenu,
        'el-dropdown-item': DropdownItem,
        CustomDropdownOption,
    },
})
export default class CustomDropdown extends Vue {
    @Prop({
        required: false,
        type: String,
        default: () => 'bottom-start',
    })
    private placement!: DropdownMenuAlignment;

    @Prop({
        required: false,
        type: String,
    })
    private label!: string;

    @Prop({
        required: false,
        type: Boolean,
        default: true,
    })
    private visible!: boolean;

    @Prop({
        required: true,
        type: Array as () => IDropdownItem[],
    })
    private items!: IDropdownItem[];

    @Prop({
        type: String,
        default: null,
    })
    private eventMask!: string;

    beforeMount() {
        if (this.eventMask) {
            document.addEventListener('hidedropdown', this.handleHideEvent);
        }
    }

    beforeDestroy() {
        document.removeEventListener('hidedropdown', this.handleHideEvent);
    }

    handleHideEvent(e: Event) {
        const event = e as CustomEvent;
        if (event.detail !== this.eventMask) return;

        this.hide();
    }

    @Watch('visible')
    closeDropdown() {
        const element = this.$refs.dropdown as (Vue | Element | Vue[] | Element[]) & { hide: () => void };

        if (!this.visible) {
            element.hide();
        }
    }

    hide() {
        const element = this.$refs.dropdown as (Vue | Element | Vue[] | Element[]) & { hide: () => void };

        element.hide();
    }
}
