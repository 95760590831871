
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import Search from '@/modules/common/components/ui-kit/search.vue';

@Component({
    components: { Search },
})
export default class StyledSearchbar extends Vue {
    // TODO: Replace by event (eg. on-typing-end)
    @Prop({ type: Function, default: () => null })
    afterType!: (query: string | null) => void;

    @Prop({ type: Boolean, default: false })
    hideSearchIcon!: boolean;

    @Prop({ type: String, required: false })
    defaultValue?: string;

    @Prop({ type: String, required: false })
    placeholder?: string;

    @Prop({ type: Array })
    autocomplete!: string[];

    isFocused = false;
    query = '';
    autocompletedValue = '';

    mounted() {
        this.query = this.defaultValue || '';
    }

    get inputElement() {
        interface VueHtmlElement extends HTMLElement, Vue {}

        const search = this.$refs.search as VueHtmlElement;
        return search.$refs.searchInput as HTMLInputElement;
    }

    onInput(query: string) {
        if (this.autocomplete && query.length > 1) {
            const result = this.autocomplete.find(s => s.toLowerCase().startsWith(query.toLowerCase()));
            this.autocompletedValue = result ? result.slice(query.length) : '';
        } else {
            this.autocompletedValue = '';
        }

        this.query = query;
        this.$emit('input', query);
    }

    handleClose() {
        if (this.inputElement.value) {
            this.inputElement.value = '';
            this.query = '';
            this.afterType(null);
        }

        this.blur();
    }

    handleFocus() {
        this.isFocused = true;
    }

    blur() {
        this.isFocused = false;
        this.autocompletedValue = '';
    }
}
