
import { inject } from '@/inversify';
import { Component, Vue, Prop } from 'vue-property-decorator';

import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import AlertsService, { AlertsServiceS } from '../alerts.service';
import AlertItem from './alert-item.vue';
import AlertModel from '../models/alert.model';

@Component({
    components: { CustomLoader, AlertItem },
})
export default class AlertPopupContent extends Vue {
    @inject(AlertsServiceS) private alertsService!: AlertsService;
    @inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;

    @Prop({
        type: Array,
        default: () => [],
    })
    alerts!: AlertModel[];

    isShowAll: boolean = false;
    minAlerts = 5;

    get haveAlerts() {
        return this.alerts.length > 0;
    }

    get haveUnreadAlerts() {
        return this.alerts.map(alert => !alert.read).length > 0;
    }

    get shownAlertsLength() {
        return this.isShowAll ? this.alerts.length : this.minAlerts;
    }

    get todayAlerts() {
        const today = new Date();
        const allTodayAlerts = this.alerts.filter(a => (a.date.getMonth() === today.getMonth() && a.date.getDate() === today.getDate()));

        return this.isShowAll
            ? allTodayAlerts
            : allTodayAlerts.slice(0, this.minAlerts);
    }

    get yesterdayAlerts() {
        if (!this.isShowAll && (this.minAlerts - this.todayAlerts.length < 1)) {
            return [];
        }

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const allYesterdayAlerts = this.alerts.filter(a => (
            a.date.getMonth() === yesterday.getMonth() && a.date.getDate() === yesterday.getDate()
        ));

        return this.isShowAll
            ? allYesterdayAlerts
            : allYesterdayAlerts.slice(0, this.minAlerts - this.todayAlerts.length);
    }

    get otherAlerts() {
        if (!this.isShowAll && (this.minAlerts - (this.todayAlerts.length + this.yesterdayAlerts.length) < 1)) {
            return [];
        }

        const today = new Date();
        const yesterday = new Date();

        yesterday.setDate(yesterday.getDate() - 1);

        const allOtherAlerts = this.alerts.filter(a => (
            a.date.getDate() !== yesterday.getDate()
            && a.date.getDate() !== today.getDate()
        ));

        return this.isShowAll
            ? allOtherAlerts
            : allOtherAlerts.slice(0, this.minAlerts - (this.todayAlerts.length + this.yesterdayAlerts.length));
    }

    get isLoading() {
        return this.alertsService.isLoading();
    }

    handleMarkReadClick() {
        this.alertsService.markAllAlertsAsRead();
    }

    handleListToggle() {
        this.isShowAll = !this.isShowAll;
        this.otelService.instantSpan({ name: 'alerts-list-toggle', prefix: LOGTYPE.CLICK }, { sendLogs: true });
    }
}
