
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VPopover } from 'v-tooltip';

@Component({
    components: { VPopover },
})
export default class CustomPopover extends Vue {
    @Prop({ required: false })
    customClass?: string;

    @Prop({
        required: true,
        validator(value: string): boolean {
            return value === 'top' || value === 'top-start' || value === 'top-end'
                || value === 'right' || value === 'right-start' || value === 'right-end'
                || value === 'bottom' || value === 'bottom-start' || value === 'bottom-end'
                || value === 'left' || value === 'left-start' || value === 'left-end';
        },
    })
    placement!: 'top' | 'top-start' | 'top-end'
    | 'right' | 'right-start' | 'right-end'
    | 'bottom' | 'bottom-start' | 'bottom-end'
    | 'left' | 'left-start' | 'left-end';

    @Prop({ required: true })
    offset!: number;

    @Prop({ required: false, default: true })
    haveArrow!: boolean;

    @Prop({ required: false })
    popoverClass!: string;

    @Prop({
        required: true,
        type: String,
        validator(value: string): boolean {
            return value === 'click' || value === 'hover';
        },
    })
    private triggerEvent!: 'click' | 'hover';
}
