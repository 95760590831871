var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"styled-search"},[_c('div',{staticClass:"autocomplete",style:({ left: _vm.hideSearchIcon ? '21px' : '53px' })},[_c('span',[_vm._v(_vm._s(_vm.query))]),_c('span',[_vm._v(_vm._s(_vm.autocompletedValue))])]),_c('Search',_vm._b({ref:"search",class:{
            'styled-search__search-bar': true,
            'styled-search__search-bar--focused': _vm.isFocused,
        },attrs:{"afterType":_vm.afterType,"inputRef":"searchInput"},on:{"focus":_vm.handleFocus,"blur":_vm.blur,"submit":function($event){return _vm.$emit('submit')},"input":_vm.onInput}},'Search',{ defaultValue: _vm.defaultValue, hideSearchIcon: _vm.hideSearchIcon, placeholder: _vm.placeholder },false)),_c('button',{class:{
            'styled-search__close-btn': true,
            'styled-search__close-btn--focused': _vm.isFocused || _vm.query,
        },attrs:{"type":"button"},on:{"click":_vm.handleClose}},[_c('i',{staticClass:"icon-X-01"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }