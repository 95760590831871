
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import { inject } from '@/inversify';
import CarsAlertItem from '@/modules/cars/alerts/components/cars-alert-item.vue';
import { Component, Vue } from 'vue-property-decorator';
import CarsAlertsService, { CarsAlertsServiceS } from '../cars-alerts.service';

@Component({
    components: { CustomLoader, CarsAlertItem },
})
export default class CarsAlertPopupContent extends Vue {
    @inject(CarsAlertsServiceS) alertsService!: CarsAlertsService;

    private isShowAll: boolean = false;
    private minAlerts = 5;

    get allAlerts() {
        const { alerts = [] } = this.alertsService;
        return alerts;
    }

    get shownAlertsLength() {
        return this.isShowAll ? this.allAlerts.length : this.minAlerts;
    }

    get todayAlerts() {
        const today = new Date();
        const allTodayAlerts = this.allAlerts.filter(a => (a.date.getMonth() === today.getMonth() && a.date.getDate() === today.getDate()));
        return this.isShowAll ? allTodayAlerts : allTodayAlerts.slice(0, this.minAlerts);
    }

    get yesterdayAlerts() {
        if (!this.isShowAll && (this.minAlerts - this.todayAlerts.length < 1)) {
            return [];
        }

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const allYesterdayAlerts = this.allAlerts.filter(a => (
            a.date.getMonth() === yesterday.getMonth() && a.date.getDate() === yesterday.getDate()
        ));

        return this.isShowAll ? allYesterdayAlerts : allYesterdayAlerts.slice(0, this.minAlerts - this.todayAlerts.length);
    }

    get otherAlerts() {
        if (!this.isShowAll && (this.minAlerts - (this.todayAlerts.length + this.yesterdayAlerts.length) < 1)) {
            return [];
        }

        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const allOtherAlerts = this.allAlerts.filter(a => (
            a.date.getDate() !== yesterday.getDate()
            && a.date.getDate() !== today.getDate()
        ));

        return this.isShowAll
            ? allOtherAlerts
            : allOtherAlerts.slice(0, this.minAlerts - (this.todayAlerts.length + this.yesterdayAlerts.length));
    }

    handleMarkReadClick() {
        this.alertsService.markAllAlertsAsRead();
    }
}
