
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class TextHighlighter extends Vue {
    @Prop({ type: String, required: true })
    private text!: string;

    @Prop({ type: Array, required: true })
    private rules!: [RegExp, string][];

    public get highlightedText() {
        let highlightedText = this.text;

        this.rules.forEach(([rule, styles]) => {
            highlightedText = highlightedText
                .replace(rule, `<span style="${styles}">$&</span>`);
        });
        return highlightedText.trim();
    }
}
