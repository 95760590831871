
import { Vue, Component } from 'vue-property-decorator';
import CarsAlertsService, { CarsAlertsServiceS } from '@/modules/cars/alerts/cars-alerts.service';
import { inject } from '@/inversify';
import CustomPopover from '@/modules/common/components/ui-kit/custom-popover.vue';
import CarsAlertReference from '@/modules/cars/alerts/components/cars-alert-reference.vue';
import CarsAlertPopupContent from '@/modules/cars/alerts/components/cars-alert-popup-content.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';

@Component({
    components: {
        CarsAlertReference, CustomPopover, CarsAlertPopupContent, LoaderWrapper,
    },
})
export default class CarsAlertComponent extends Vue {
    @inject(CarsAlertsServiceS) private carAlertsService!: CarsAlertsService;

    get amountAlerts(): number {
        const { alerts } = this.carAlertsService;
        return alerts ? alerts.filter(a => !a.read).length : 0;
    }
}
