
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ZendeskService, { ZendeskServiceS } from '@/modules/zendesk-chat/zendesk.service';

@Component
export default class chatButton extends Vue {
    @inject(ZendeskServiceS) private zendeskService!: ZendeskService;
    mounted() {
        this.zendeskService.init('zendesk-chat-button');
    }
    openZendeskChat() {
        this.zendeskService.openChat();
    }
}
