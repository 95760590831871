
import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsAlertComponent from '@/modules/cars/alerts/components/cars-alert-component.vue';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import CarsProfileMenu, { ICarsProfileMenuItem } from '@/modules/cars/components/cars-profile-menu.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import BadRequestException from '@/modules/common/modules/exception-handler/exceptions/bad-request.exception';

@Component({
    components: { CarsAlertComponent, CarsProfileMenu },
})
export default class CarsHeader extends Vue {
    @inject(AuthServiceS) protected authService!: AuthService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(ConfigServiceS) configService!: ConfigService;
    @inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;

    get dropdownItems(): ICarsProfileMenuItem[] {
        const {
            isHotelUser,
            isCarUser,
            isAdmin,
        } = this.userService;

        return [
            {
                text: 'Rooms Type Manager',
                link: 'rooms-mapping',
                isVisible: isHotelUser || false,
            },
            {
                text: 'Car category mapping',
                link: 'cars-mapping',
                isVisible: isCarUser || false,
            },
            {
                text: 'Fleet SIPP code mappings',
                link: 'sipp',
                isVisible: isCarUser || false,
            },
            {
                text: 'Settings',
                link: 'car-settings',
                isVisible: isCarUser || false,
                isParentURL: true,
            },
            {
                text: 'View as',
                link: '',
                viewAs: true,
            },
            {
                text: 'Admin',
                link: '',
                isVisible: isAdmin,
                action: this.toAdmin,
            },
            {
                text: 'Log Out',
                link: '',
                action: this.logout,
            },
        ];
    }

    get logo() {
        const images = require.context('@/modules/common/assets/', false, /\.png$|\.jpg$|\.svg$/);
        if (!this.carsFilterService.settings.theme) {
            return images('./fornova-cr-logo.svg');
        }
        if (!this.carsFilterService.settings.theme.logo) {
            return images('./fornova-cr-logo.svg');
        }
        return this.carsFilterService.settings.theme.logo;
    }

    get defaultLogo() {
        return '@/modules/common/assets/fornova-cr-logo.svg';
    }

    get userName() {
        const { user } = this.userService.storeState;
        if (!user) {
            return null;
        }

        return `${user.firstName} ${user.lastName}`;
    }

    get userEmail() {
        const { user } = this.userService.storeState;
        if (!user) {
            return null;
        }

        return user.email;
    }

    get diAdminUrl() {
        return this.configService.diAdminUrl;
    }

    toAdmin() {
        if (!this.diAdminUrl) {
            throw new BadRequestException('No VUE_APP_DI_URL specified in the app.config.json!');
        }

        window.location.href = `${this.diAdminUrl}/login-token/${encodeURIComponent(localStorage.getItem('token') as string)}`;
    }

    logout() {
        this.authService.logout();
    }
}
