
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui';
import { RawLocation, RouterLink } from 'vue-router';

export interface IDropdownItem {
    text: string,
    link?: RawLocation,
    action?: Function,
    isVisible?: boolean,
    divided?: boolean,
}

@Component({
    components: {
        Dropdown,
        DropdownMenu,
        DropdownItem,
    },
})
export default class ProfileMenu extends Vue {
    @Prop({ type: String })
    label!: string;

    @Prop({ type: String })
    email!: string;

    @Prop({
        required: true,
        type: Array as () => IDropdownItem[],
        validator(value: any): boolean {
            return value.every((item: any) => ('text' in item
                && typeof item.text === 'string'));
        },
    })
    items!: IDropdownItem[];

    handleAction(action?: Function) {
        if (action) action();
    }
}
